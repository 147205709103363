/******************************************
*  Author : Suraj Sharma   
*  Created On : Tue Jan 19 2021
*  File : Commons.module.css
*******************************************/

.clusterContainer {
  max-width: 100px;
  max-height: 100px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  cursor: pointer;
}

.clusterSpan {
  display: inline-block;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  padding: 2px;
  background-color: rgb(255, 255, 255);
  border: 2px solid green;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
}
.clusterSpan:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
  height: 0;
}

.counterContainer {
  z-index: 1;
  position: absolute;
  right: 0px;
  top: 90px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.counter {
  padding: 10px 20px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.counterMessage {
  font-size: 12px;
  margin-left: 10px;
  font-weight: bold;
}

.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgb(3, 3, 3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000;
  opacity: 0.96;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.loaderImage {
  object-fit: contain;
  width: 100%;
  height: 100%;
  margin: auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.acceptButtonNotice {
  width: 30%;
  background-color: #4ebd86;
  color: white;
  padding: 5px 0px;
  border-radius: 20px;
}

.rejectButtonNotice {
  width: 30%;
  background-color: #4a4a4a;
  color: white;
  padding: 5px 0px;
  border-radius: 20px;
}

.timePickerContainer {
  position: absolute;
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
}

.pickerColumn {
  display: flex;
  flex-direction: column;
  width: 40px;
}

/* The ModalImage (background) */
.imageModal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  overflow: none; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* imageModal Content (image) */
.imageModalContent {
  margin: auto;
  display: block;
  /* width: auto;
	height: auto; */
  object-fit: cover;
  max-width: 700px;
}

/* Add Animation */
.imageModalContent {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.imageModalClose {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.imageModalClose:hover,
.imageModalClose:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .imageModalContent {
    width: 100%;
  }
}
